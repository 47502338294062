import { defiverseTokens } from '@pancakeswap/tokens'
// import { defiverseTestnetTokens } from '@pancakeswap/tokens'
import { FeeAmount } from '@pancakeswap/v3-sdk'
import { getAddress } from 'viem'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'
// import { defiverseTokens } from '@pancakeswap/tokens'

import { FarmConfigV3, SerializedFarmConfig } from '..'

const v3TopFixedLps: FarmConfigV3[] = []

export const farmsV3 = defineFarmV3Configs([
  ...v3TopFixedLps,
  {
    pid: 1,
    lpAddress: '0x65A1133a3a3CCbDC56Ae951834DE45Bab1cA3446',
    token0: defiverseTokens.weth,
    token1: defiverseTokens.usdc,
    feeAmount: FeeAmount.LOW,
  },
  // {
  //   pid: 2,
  //   lpAddress: '0xfc45871d6c0Df3CDAE736dB52B2d81ac61EcF6eF',
  //   token0: defiverseTokens.weth,
  //   token1: defiverseTokens.mockB,
  //   feeAmount: FeeAmount.MEDIUM,
  // },
  // {
  //   pid: 3,
  //   lpAddress: '0x9c7505095aA5a1B2227822BA47D13054eC570972',
  //   token0: defiverseTokens.mockB,
  //   token1: defiverseTokens.mockC,
  //   feeAmount: FeeAmount.HIGH,
  // },
])

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 2, 3) should always be at the top of the file.
   */
  {
    pid: 0,
    v1pid: 0,
    lpSymbol: 'CAKE',
    lpAddress: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    token: defiverseTokens.weth,
    quoteToken: defiverseTokens.usdc,
  },
].map((p) => ({
  ...p,
  token: p.token.serialize,
  quoteToken: p.quoteToken.serialize,
  lpAddress: getAddress(p.lpAddress),
}))
export default farms
