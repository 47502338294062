import { ChainId } from '@pancakeswap/chains'

// export const SUPPORT_ONLY_BSC = [ChainId.BSC]
export const SUPPORT_ONLY_BSC = [ChainId.DEFIVERSE, ChainId.DEFIVERSE_TESTNET, ChainId.FANTOM_TESTNET]
// export const SUPPORT_FARMS = supportedChainId
export const SUPPORT_FARMS = [ChainId.DEFIVERSE, ChainId.DEFIVERSE_TESTNET, ChainId.FANTOM_TESTNET]
export const POOL_SUPPORTED_CHAINS = [
  ChainId.BSC,
  // ChainId.DEFIVERSE,
  ChainId.DEFIVERSE_TESTNET,
  ChainId.FANTOM_TESTNET,
]
export const SUPPORT_BUY_CRYPTO = [
  // ChainId.BSC,
  // ChainId.ETHEREUM,
  // ChainId.ARBITRUM_ONE,
  // ChainId.ZKSYNC, // NO PROVIDER SUPPORT ZK_SYNC_ERA
  // ChainId.POLYGON_ZKEVM,
  // ChainId.LINEA,
  // ChainId.BASE,
]

export const LIQUID_STAKING_SUPPORTED_CHAINS = [
  // ChainId.BSC,
  // ChainId.ETHEREUM,
  // ChainId.BSC_TESTNET,
  // ChainId.ARBITRUM_GOERLI,
]
// export const FIXED_STAKING_SUPPORTED_CHAINS = [ChainId.BSC]

// export const FIXED_STAKING_SUPPORTED_CHAINS = [ChainId.DEFIVERSE_TESTNET]
export const FIXED_STAKING_SUPPORTED_CHAINS = [
  // ChainId.DEFIVERSE,
  ChainId.DEFIVERSE_TESTNET,
  // ChainId.DEFIVERSE,
  // ChainId.FANTOM,
  ChainId.FANTOM_TESTNET,
]

// export const V3_MIGRATION_SUPPORTED_CHAINS = [ChainId.BSC, ChainId.ETHEREUM]
// export const V3_MIGRATION_SUPPORTED_CHAINS = [ChainId.DEFIVERSE_TESTNET]
export const V3_MIGRATION_SUPPORTED_CHAINS = []

// export const SUPPORT_CAKE_STAKING = [ChainId.BSC, ChainId.BSC_TESTNET]
export const SUPPORT_CAKE_STAKING = [
  // ChainId.DEFIVERSE,
  ChainId.DEFIVERSE_TESTNET,
  // ChainId.DEFIVERSE,
  // ChainId.FANTOM,
  ChainId.FANTOM_TESTNET,
]
